/**
 * Type check for falsy values.
 *
 * Used as the callback for array.filter, e.g.
 * items.filter(falsy)
 */
export function falsy<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined
}

/**
 * This takes in an object, removes the __typename property, takes its
 * remaining keys and checks if it extends "never". An empty object has the
 * never type, therefore we return false to indicate that it has no additional
 * properties.
 */
type HasAdditionalProperties<T> = keyof Omit<T, '__typename'> extends never
  ? false
  : true

/**
 * Infer the allowed typenames.
 *
 * This is used only for DX. When filtering an array of GraphQL objects by a
 * type, we only want to allow types that actually have more fields than just
 * __typename.
 *
 * Assume the following array:
 * [
 *   {
 *     __typename: 'NodePressRelease',
 *   },
 *  {
 *     __typename: 'NodePressRelease',
 *   },
 *  {
 *     __typename: 'NodePage',
 *     title: 'Hello world',
 *   },
 * ]
 *
 * It's unlikely that anyone would want to filter for "NodePressRelease" in
 * this case, so we filter out all the objects that only have one property
 * (__typename).
 *
 * In this example, the result will be:
 * T = 'NodePage'
 */
type InferAllowedTypenames<T extends { __typename: string }> = {
  [K in T['__typename']]: HasAdditionalProperties<
    Extract<T, { __typename: K }>
  > extends true
    ? K
    : never
}[T['__typename']]

/**
 * Check if the object is of the given GraphQL type.
 */
function isTypeName<T extends { __typename: string }>(
  item: T,
  typename: T['__typename'],
): item is T {
  return item.__typename === typename
}

/**
 * Filter an array of GraphQL objects by one or more types.
 *
 * Some queries like entityQuery return items as Entity interface, even if the
 * query conditions only ever return Node for example. This method filters the
 * array to only include the desired types and narrows down the proper fragment
 * type.
 */
export function filterByTypenames<
  T extends { __typename: string },
  K extends InferAllowedTypenames<T>,
>(
  items: Array<T> = [],
  typenames: Array<K> | K,
): Array<Extract<T, { __typename: K }>> {
  return items.filter(
    (item) =>
      item &&
      (typeof typenames === 'string'
        ? isTypeName(item, typenames)
        : typenames.some((typename) => isTypeName(item, typename))),
  ) as Array<Extract<T, { __typename: K }>>
}

export type ArrayElement<ArrayType extends readonly unknown[] | unknown> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }

/**
 * Filter an array of objects to only include objects that have a truthy value
 * for every given property.
 */
export function withRequiredProperties<T, K extends keyof T>(
  items: T[],
  keys: K[],
): WithRequired<T, K>[] {
  if (!items) {
    return []
  }
  // Filter items to only keep those items that have all properties.
  return items.filter((item) => {
    return keys.every((key) => item[key])
  }) as WithRequired<T, K>[]
}
